import { refreshToken } from './auth-requests';
import { getModal } from '../../contexts/ModalContext';
import i18next from '../../i18n';
import LoginModalForm from '../../form/LoginModalForm/LoginModalForm';
import { getLocalStorage } from '../../utils/localStorage';
import { WarningTriangleRedIcon } from '../../images/shapes';
import { fetchLogin } from './fetch-login';

class RefreshStatus {
  constructor() {
    this.reset();
    this.newJwt = '';
  }

  reset() {
    this.isRefreshing = false;
    this.isRefreshed = false;
    this.isInvalid = false;
    this.fetchRequest = null;
    this.loginRequest = null;
  }
}

const refreshStatus = new RefreshStatus();

export const resetRefreshStatus = () =>
  Object.assign(refreshStatus, new RefreshStatus());

const refreshTokenCallback = async () => {
  try {
    return await refreshToken();
  } catch {
    window.location.replace('/logout?reason=auth-error');
  }
};

export class MissingParamError extends Error {
  constructor(url) {
    super(`Not all params are provided to url: ${url}`);
  }
}

const showLoginModal = async () => {
  const email = getLocalStorage('cms.user', true)?.data?.email;
  const modal = getModal();
  const token = await modal({
    title: (
      <div className="inline-flex text-red font-bold text-3xl items-center">
        <WarningTriangleRedIcon className="h-5 mr-2.5" />
        {i18next.t('Global.SessionExpired')}
      </div>
    ),
    content: (
      <LoginModalForm
        email={email}
        onSubmit={(values, setUser) =>
          fetchLogin({ password: values.password, email }, setUser, i18next.t)
        }
      />
    ),
    hideClose: true,
  });
  refreshStatus.newJwt = token;
};

/**
 * Creates fetch method
 * @param {string} url server url
 * @param {string} method valid http method (GET, POST, PUT, PATCH, DELETE)
 * @param {string} jwt A token used for communication
 * @param space
 * @param {Record<string, any>} options additional options used for query
 * @returns {Promise<Response>}
 */
export const fetchMethod = (url, method, jwt, space, options = null) =>
  fetch(url, {
    method,
    ...options,
    headers: {
      Accept: 'application/json',
      ...(jwt ? { Authorization: `JWT ${jwt}` } : {}),
      ...(options?.skipContentType
        ? {}
        : { 'Content-Type': 'application/json; charset=UTF-8' }),
      ...(space ? { 'X-SPACE-ID': space } : {}),
      ...(options?.headers ?? {}),
    },
  });

/**
 * Creates fetch method
 * @param {string} url server url
 * @param {string} method valid http method (GET, POST, PUT, PATCH, DELETE)
 * @param {Record<string, any>} options additional options used for query
 * @returns {Promise<Response>}
 */
export const unauthenticatedFetchMethod = (url, method, options = null) =>
  fetchMethod(url, method, undefined, undefined, options);

/**
 *
 * @param {string} method valid http method (GET, POST, PUT, PATCH, DELETE)
 * @param {string} path A path relative to server url
 * @param {string} jwt A token used for communication
 * @param {string} space Space ID for specifying the project
 * @param {Record<string, any>} options additional options used for query
 * @returns {Promise<Response>}
 */
export const apiRequest = async (method, path, jwt, space, options = null) => {
  const baseUrl = process.env.REACT_APP_FLOTIQ_API_URL;
  const url = `${baseUrl.replace(/\/+$/, '')}/${path.replace(/^\/*/, '')}`;

  const impesonateString = sessionStorage.getItem('cms.impersonate');
  const impersonate = impesonateString ? JSON.parse(impesonateString) : null;
  const requestOptions = {
    ...(options ?? {}),
    ...(impersonate
      ? {
          headers: {
            'X-SWITCH-USER': impersonate.email,
          },
        }
      : null),
  };

  const response = await fetchMethod(url, method, jwt, space, requestOptions);

  if (response?.status === 401 && jwt) {
    if (!refreshStatus.isRefreshing) {
      refreshStatus.isRefreshing = true;

      refreshStatus.fetchRequest = refreshTokenCallback();
      const refreshResponse = await refreshStatus.fetchRequest;

      if (!refreshResponse) {
        //if there is no refresh response do nothing and return request
      } else if (refreshResponse.ok) {
        localStorage.setItem('cms.user', JSON.stringify(refreshResponse.body));

        window.dispatchEvent(
          new StorageEvent('storage', {
            storageArea: window.localStorage,
            url: window.location.href,
            key: 'cms.user',
          }),
        );

        refreshStatus.newJwt = refreshResponse.body.token;
      } else {
        refreshStatus.isInvalid = true;
        refreshStatus.loginRequest = showLoginModal();
      }
      refreshStatus.isRefreshed = true;
    } else {
      await refreshStatus.fetchRequest;
    }

    await refreshStatus.loginRequest;
    refreshStatus.reset();

    return fetchMethod(
      url,
      method,
      refreshStatus.newJwt,
      space,
      requestOptions,
    );
  }

  return response;
};

/**
 *
 *
 * @param {string} url an url with variable keys in it, e.g. api/data/{{id}}
 * @param {Record<string, any>} params parameters to take url variables from, e.g. {id: 123}
 * @returns {string} url with substituted values, e.g. api/data/123
 */
const applyUrlParams = (url, params) => {
  const newUrl = Object.keys(params).reduce((url, key) => {
    const newUrl = url.replaceAll(
      `{{${key}}}`,
      encodeURIComponent(params[key]),
    );
    if (newUrl !== url) delete params[key];

    return newUrl;
  }, url);

  if (/{{[a-z0-9]+}}/i.test(newUrl)) throw new MissingParamError(url);

  return newUrl;
};

/**
 * Appends url params to the url
 * @param {string} url Base url that params are appended to
 * @param {Record<string,string>} params a key/value object of params
 * @returns {string} Url combined with query params
 */
export const appendQueryParams = (url, params) => {
  const keyValues = [];
  Object.keys(params).forEach((key) => {
    if (params[key] != null) {
      const encodedKey = encodeURIComponent(key);
      if (Array.isArray(params[key])) {
        const arrayString = params[key]
          .map((el) => `${encodedKey}[]=${encodeURIComponent(el)}`)
          .join('&');
        keyValues.push(arrayString);
      } else {
        keyValues.push(`${encodedKey}=${encodeURIComponent(params[key])}`);
      }
    }
  });
  const queryString = keyValues.join('&');
  if (!queryString) return url;
  if (url.includes('?')) {
    return `${url}&${queryString}`;
  } else {
    return `${url}?${queryString}`;
  }
};

/**
 * Parses response into a valid object depending on response content-type
 * @param {Response} res response acquired from server
 * @returns {*}
 */
export const defaultResponseParser = async (res) => {
  const headers = Object.fromEntries(res.headers.entries());
  if (headers['content-type']?.includes('application/json'))
    return { status: res.status, body: await res.json(), ok: res.ok };
  return res;
};

/**
 * Creates a method for querying a listing url
 *
 * @param {string} name endpoint used for this URL
 * @param {string} method REST method, default = GET
 * @param {Record<string, any>} defaultParams default parameters added to query url
 */
export function makeBodilessQuery(name, method = 'GET', defaultParams = {}) {
  /**
   * Bodiless query function. Used for GET, DELETE and HEAD queries
   * @param {String} jwt Token used for query
   * @param {String} space Space id for query
   * @param {object} params values used with query and url params
   * @param {object} options fetch options
   * @returns {Promise<Response|{body: any, status: number, ok: boolean}>}
   */
  return (jwt, space, params = defaultParams, options = {}) => {
    let url = `api/${name}`;
    const paramsLocalCopy = JSON.parse(JSON.stringify(params || {}));
    url = applyUrlParams(url, paramsLocalCopy);
    url = appendQueryParams(url, { ...defaultParams, ...paramsLocalCopy });
    return apiRequest(method, url, jwt, space, options).then(
      defaultResponseParser,
    );
  };
}

const buildUrlAndBody = (name, body, otherParams = null) => {
  let url = `api/${name}`;
  const bodyLocalCopy = Array.isArray(body)
    ? Object.assign({}, body)
    : JSON.parse(JSON.stringify(body));

  url = applyUrlParams(url, otherParams || bodyLocalCopy);
  return { url, bodyLocalCopy };
};

/**
 *
 * @param {string} name endpoint used for this url
 * @param {string} method
 */
export function makeJSONQuery(name, method = 'POST') {
  /**
   * JSON query function. Used for POST, PUT, and PATCH queries
   * @param {String} jwt Token used for query
   * @param {String} space Space id used for query
   * @param {object} params values used with body and url params
   * @param {object} options fetch options
   * @returns {Promise<Response|{body: any, status: number, ok: boolean}>}
   */
  return (jwt, space, body = {}, options = {}) => {
    let { url, bodyLocalCopy } = buildUrlAndBody(name, body);
    return apiRequest(method, url, jwt, space, {
      body: JSON.stringify(bodyLocalCopy),
      ...options,
    }).then(defaultResponseParser);
  };
}

/**
 *
 * @param {string} name endpoint used for this url
 * @param {string} method
 */
export function makeJSONQueryWithGet(name, method = 'POST') {
  /**
   * JSON query function. Used for POST, PUT, and PATCH queries
   * @param {String} jwt Token used for query
   * @param {String} space Space id used for query
   * @param {object} params values used with body and url params
   * @param {object} options fetch options
   * @returns {Promise<Response|{body: any, status: number, ok: boolean}>}
   */
  return (jwt, space, getParams, body = {}, options = {}) => {
    let { url, bodyLocalCopy } = buildUrlAndBody(name, body, getParams);
    const paramsLocalCopy = JSON.parse(JSON.stringify(getParams || {}));
    url = appendQueryParams(url, { ...paramsLocalCopy });
    return apiRequest(method, url, jwt, space, {
      body: JSON.stringify(bodyLocalCopy),
      ...options,
    }).then(defaultResponseParser);
  };
}

/**
 *
 * @param {string} name endpoint used for this url
 * @param {string} method
 */
export function makeBatchJSONQuery(name, method = 'POST') {
  /**
   * JSON query function. Used for POST, PUT, and PATCH queries
   * @param {String} jwt Token used for query
   * @param {String} space Space id used for query
   * @param {String} contentTypeName CTD name id used for query
   * @param {object} body values used with body and url params
   * @param {object} options fetch options
   * @returns {Promise<Response|{body: any, status: number, ok: boolean}>}
   */
  return (jwt, space, contentTypeName, body = {}, options = {}) => {
    let { url, bodyLocalCopy } = buildUrlAndBody(name, body, {
      contentTypeName,
    });
    return apiRequest(method, url, jwt, space, {
      body: JSON.stringify(bodyLocalCopy),
      ...options,
    }).then(defaultResponseParser);
  };
}

/**
 *
 * @param {string} name endpoint used for this url
 * @param {string} method
 */
export function makeUnauthenticatedJSONQuery(name, method = 'POST') {
  /**
   * JSON query function. Used for POST, PUT, and PATCH queries
   * @param {object} params values used with body and url params
   * @param {object} options fetch options
   * @returns {Promise<Response|{body: any, status: number, ok: boolean}>}
   */
  return (body = {}, options = {}) => {
    let { url, bodyLocalCopy } = buildUrlAndBody(name, body);
    return apiRequest(method, url, undefined, undefined, {
      body: JSON.stringify(bodyLocalCopy),
      ...options,
    }).then(defaultResponseParser);
  };
}

export function makeExternalServiceQuery(
  url,
  method = 'GET',
  requestOptions = {},
  defaultParams = {},
  withJwt = false,
) {
  /**
   * Bodiless query function. Used for GET, DELETE and HEAD queries
   * @param {String} jwt Token used for query
   * @param {String} space Space id for query
   * @param {object} params values used with query and url params
   * @param {object} options fetch options
   * @returns {Promise<Response|{body: any, status: number, ok: boolean}>}
   */
  return (jwt, _space, params = defaultParams, options = {}) => {
    let urlWithParams = url;
    const paramsLocalCopy = JSON.parse(JSON.stringify(params || {}));
    urlWithParams = applyUrlParams(url, paramsLocalCopy);
    urlWithParams = appendQueryParams(urlWithParams, {
      ...defaultParams,
      ...paramsLocalCopy,
    });

    const finalOptions = { ...requestOptions, ...options };

    return fetchMethod(
      urlWithParams,
      method,
      withJwt ? jwt : undefined,
      undefined,
      finalOptions,
    ).then(defaultResponseParser);
  };
}
