import {
  Fragment,
  useEffect,
  useState,
  useMemo,
  createRef,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';
import { createPortal } from 'react-dom';

// :: Component
import { SectionMenuItemChild } from './SectionMenuItemChild';
import Tooltip from '../Tooltip/Tooltip';
import { popoverUIMenuClasses } from '../Popover/Popover';
import { baseUIPopoverItemClasses } from '../Popover/PopoverItem/PopoverItem';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Images
import { CaretDownIcon } from '../../images/shapes';

/**
 * Outside Listener event to close popover when clicked beyond open element
 * @param {ref} ref reference to current opened popover
 * @param {func} handler function callback clicked outside open element
 * @param {bool} hasEventOnCloseOutside allow to add mouse downe event listener based on existed references
 */
const useOutsideListener = (ref, handler, hasEventOnCloseOutside) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        handler?.();
      }
    };

    hasEventOnCloseOutside &&
      document.addEventListener('mousedown', handleClickOutside);

    return () => {
      hasEventOnCloseOutside &&
        document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler, hasEventOnCloseOutside]);
};

const MenuItem = ({
  link,
  linkTarget,
  linkRel,
  onClick,
  isOpen,
  children,
  title,
  tooltip,
  disabled,
  testId,
}) => {
  const itemContainerClasses = twMerge(
    'flex h-full pl-5 pr-3 items-center dark:decoration-white user-drag-none text-left',
    isOpen && 'w-full',
    disabled && 'cursor-not-allowed opacity-50',
  );

  const itemRef = useRef();
  const [currentTop, setCurrentTop] = useState();

  const handlePosition = useCallback(() => {
    setCurrentTop(itemRef.current?.getBoundingClientRect()?.top);
  }, []);

  const resetCurrentTop = useCallback(() => {
    setCurrentTop();
  }, []);

  const handleClick = useCallback(
    (e) => {
      if (disabled) return;
      onClick?.(e);
    },
    [disabled, onClick],
  );

  const portalTooltip =
    (!isOpen || tooltip) &&
    currentTop &&
    title &&
    createPortal(
      <div
        className="fixed top-0 left-0 z-[90] pointer-events-none"
        style={{
          top: currentTop,
        }}
      >
        <Tooltip
          tooltip={tooltip || title}
          tooltipPlacement={!isOpen ? 'rightCenter' : 'topCenter'}
          phoneTooltipPlacement={!isOpen ? 'rightCenter' : 'topCenter'}
          additionalTooltipClasses={twMerge(
            'group-focus/tooltip:!invisible group-active/tooltip:!invisible',
            '!visible !opacity-100 !max-w-52',
          )}
        >
          <div className={twMerge(!isOpen ? 'w-16' : 'w-60', 'h-10')} />
        </Tooltip>
      </div>,
      document.body,
    );

  if (link)
    return (
      <Link
        ref={itemRef}
        className={itemContainerClasses}
        to={link}
        onClick={handleClick}
        target={linkTarget}
        rel={linkRel}
        onMouseEnter={handlePosition}
        onMouseLeave={resetCurrentTop}
        onFocus={resetCurrentTop}
        {...getTestProps(testId, 'link')}
      >
        {children}
        {portalTooltip}
      </Link>
    );

  return (
    <button
      ref={itemRef}
      className={itemContainerClasses}
      onClick={handleClick}
      onMouseEnter={handlePosition}
      onMouseLeave={resetCurrentTop}
      onFocus={resetCurrentTop}
      {...getTestProps(testId, 'div')}
    >
      {children}
      {portalTooltip}
    </button>
  );
};

export const SectionMenuItem = ({
  menuItems,
  isListOpenSingle,
  selected,
  isOpen,
  handleParentOpen,
  openItem,
  selectedSeparator,
  parent,
  additionalMenuItemClasses,
  additionalMenuItemIconClasses,
  additionalMenuItemChildClasses,
  pinContentCallback,
  hidePin,
  testId,
}) => {
  const [open, setOpen] = useState({ [openItem]: true });
  const [currentKeyId, setCurrentKeyId] = useState();
  const refsById = useMemo(() => {
    const refs = {};
    menuItems.forEach((item) => {
      if (item.popover) {
        refs[item.key] = createRef(null);
      }
    });
    return refs;
  }, [menuItems]);

  useOutsideListener(
    refsById[currentKeyId],
    () => setOpen({ ...open, [currentKeyId]: false }),
    Object.keys(refsById).length > 0,
  );

  useEffect(() => {
    if (openItem) {
      setOpen({ [openItem]: true });
    } else {
      setOpen({});
    }
  }, [openItem]);

  const handleOpenList = (key, popover) => {
    const openListStatus = {
      [key]: open[key] === undefined ? true : !open[key],
    };
    setCurrentKeyId(key);

    if (isListOpenSingle && !popover) {
      setOpen(openListStatus);
    } else {
      setOpen({
        ...open,
        ...openListStatus,
      });
    }

    // Case: Open menu when onClick we open child list
    if (!isOpen && handleParentOpen) {
      handleParentOpen(true);
    }
  };

  const handleClickInPopover = useCallback(
    (popover, key) => {
      if (popover) {
        setOpen({
          ...open,
          [key]: false,
        });
      }
    },
    [open],
  );

  return (
    <>
      <ul {...getTestProps(testId, 'container')}>
        {menuItems?.map((item) => {
          let isSelected = selected?.startsWith(
            parent
              ? parent + selectedSeparator + item.key + selectedSeparator
              : item.key,
          );

          const isOpenable = item?.popover
            ? item?.children?.length > 1
            : item?.children?.length > 0;

          return (
            <Fragment key={'menu-item' + item.title}>
              <li
                className={twMerge(
                  'group flex w-full relative h-10 mb-1.5 text-xl  ',
                  !item?.disabled &&
                    'cursor-pointer hover:bg-gradient-menu-hover',
                  isSelected &&
                    !item?.children?.length &&
                    'border-blue border-r-4 bg-gradient-menu-active',
                  item.title.length > 20 && 'h-12',
                  item.popover &&
                    isOpen &&
                    open[item.key] &&
                    'pointer-events-none',
                  item.className,
                  additionalMenuItemClasses,
                )}
                {...(isOpenable && {
                  onClick: () => handleOpenList(item.key),
                })}
                {...getTestProps(testId, item.title)}
              >
                <MenuItem
                  link={item?.link}
                  linkTarget={item?.target}
                  linkRel={item?.rel}
                  title={item?.title}
                  onClick={item?.onClick}
                  isOpen={isOpen}
                  tooltip={item?.tooltip}
                  disabled={item?.disabled}
                  {...getTestProps(testId, item.title, 'testId')}
                >
                  {item.icon}

                  <div
                    className={twMerge(
                      'whitespace-pre-wrap',
                      'overflow-hidden ml-3',
                      'transition-all ease-linear',
                      'duration-normal text-base',
                      'line-clamp-2 leading-normal font-medium py-0.5 dark:text-white dark:decoration-white',
                      item?.children?.length > 0 && 'mr-8',
                      !isOpen && 'w-0',
                      item?.titleClassName,
                    )}
                    title={item.title.length > 20 ? item.title : undefined}
                    {...getTestProps(testId, `title-${item.title}`)}
                  >
                    {item.title}
                  </div>

                  {isOpenable && isOpen && (
                    <CaretDownIcon
                      className={twMerge(
                        'absolute right-4 w-3',
                        'select-none transition-all',
                        'ease-linear duration-normal text-indigo-950 dark:text-white',
                        open[item.key] && 'rotate-180',
                        additionalMenuItemIconClasses,
                      )}
                      {...getTestProps(testId, `arrow-down-${item.title}`)}
                    />
                  )}
                </MenuItem>
              </li>

              {item?.children?.length > 0 && (
                <li className="relative">
                  <SectionMenuItemChild
                    ref={refsById[item.key]}
                    parent={
                      parent ? parent + selectedSeparator + item.key : item.key
                    }
                    items={item.children}
                    selected={selected}
                    selectedSeparator={selectedSeparator}
                    pinContentCallback={pinContentCallback}
                    hidePin={hidePin || item.hidePin}
                    show={isOpen && open[item.key]}
                    handleParentOpen={() => handleOpenList(item.key)}
                    additionalMenuItemChildClasses={twMerge(
                      'pl-0',
                      additionalMenuItemChildClasses,
                      item.popover && 'hover:bg-none',
                    )}
                    additionalMenuItemChildContainerClasses={twMerge(
                      item.popover &&
                        'pb-0 absolute z-10 h-0 left-5 w-[205px] max-w-[205px] ' +
                          `${
                            isOpen && open[item.key]
                              ? 'h-auto ' + popoverUIMenuClasses
                              : ''
                          }`,
                    )}
                    additionalMenuItemChildInnerClasses={twMerge(
                      item.popover &&
                        baseUIPopoverItemClasses + ' h-auto 2xl:h-auto',
                      item.popover && !item.hidePin && 'pl-14',
                    )}
                    onClick={() => handleClickInPopover(item.popover, item.key)}
                    {...getTestProps(testId, 'child', 'testId')}
                  />
                </li>
              )}
            </Fragment>
          );
        })}
      </ul>
    </>
  );
};

SectionMenuItem.propTypes = {
  /**
   * Menu Items
   */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      icon: PropTypes.node,
      title: PropTypes.string,
      link: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          title: PropTypes.string,
          link: PropTypes.string,
          disabled: PropTypes.bool,
          tooltip: PropTypes.node,
        }),
      ),
    }),
  ),
  /**
   * If we want to open multiple parent at once.
   */
  isListOpenSingle: PropTypes.bool,
  /**
   * Name of the title that we want to select
   */
  selected: PropTypes.string,
  /**
   * If sidebar is opened
   */
  isOpen: PropTypes.bool,
  /**
   * Handler that will work only for isOpen:false to trigger func to be able to open sidebar
   */
  handleParentOpen: PropTypes.func,
  /**
   * Name of the Title that should be open if they have children
   */
  openItem: PropTypes.string,
  /**
   * Separator that is used to help validate if `selected` string could be with any separator.
   * This will be validated only if `isSelectedIncludeParent`:`true`.
   * Example: If we have "Faq" as a parent name and also "Faq" is
   * children inside "Content" and we use *title* in `selectedBy`.
   * Then select will look like that: "Content*Faq"
   */
  selectedSeparator: PropTypes.string,
  /**
   * Parent key for children elements
   */
  parent: PropTypes.string,
  /**
   * If pin should be hidden for children
   */
  hidePin: PropTypes.bool,
  /**
   * Additional Menu Item Classes
   */
  additionalMenuItemClasses: PropTypes.string,
  /**
   * Additional Menu Item Icon Classes
   */
  additionalMenuItemIconClasses: PropTypes.string,
  /**
   * Additional Menu Item Child Classes
   */
  additionalMenuItemChildClasses: PropTypes.string,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

SectionMenuItem.defaultProps = {
  menuItems: [],
  isListOpenSingle: true,
  selected: '',
  isOpen: true,
  openItem: '',
  parent: '',
  selectedSeparator: '/',
  additionalMenuItemClasses: '',
  additionalMenuItemIconClasses: '',
  additionalMenuItemChildClasses: '',
  testId: '',
  pinContentCallback: null,
  hidePin: false,
};
