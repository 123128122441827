import useLocalStorageState from 'use-local-storage-state';
import { useApiKeys, useContentTypes, useUser } from '../api';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import UserContext from '../../contexts/UserContext';
import useDarkMode from '../useDarkMode';
import {
  ClipboardColorIcon,
  ClipboardColorIconWhite,
  ConnectedDotsColorIcon,
  ConnectedDotsColorIconWhite,
  CopyColorIcon,
  CopyColorIconWhite,
  FolderIcon,
  FolderIconWhite,
  FourSquaresColor,
  FourSquaresColorWhite,
  GearColorIcon,
  GearColorIconWhite,
  KeyColorIcon,
  KeyColorIconWhite,
  LightningColorIcon,
  LightningColorIconWhite,
  MediaColorIcon,
  MediaColorIconWhite,
  MenuIcon,
  MenuIconWhite,
  PuzzleColorIcon,
  PuzzleColorIconWhite,
  StarFavIcon,
  TagsColorIcon,
  TagsColorIconWhite,
  UsersIcon,
  UsersKeyIcon,
  UsersKeyIconWhite,
} from '../../images/shapes';
import { useTranslation } from 'react-i18next';
import useSelectedSpace from '../useSelectedSpace';
import { generateApiDocs } from '../../lib/helpers';
import { markTaskAsDone } from '../../lib/flotiq-client/api-helpers';
import TagManager from 'react-gtm-module';
import useToken from '../useToken';
import useApiErrorsToast from '../api/useApiErrorsToast';
import {
  getUserPreferences,
  isViewOverridden,
  hasHiddenData,
} from './UISettings/uiSettings';
import FlotiqPlugins from '../../lib/flotiq-plugins/flotiqPluginsRegistry';
import { useNavigate } from 'react-router-dom';
import { putUserConfig } from '../../lib/flotiq-client';

const APIKEY_PARAMS = { name: 'read only api key' };

const CTD_PARAMS = {
  internal: 0,
  limit: 10000,
  order_by: 'label',
  order_direction: 'asc',
  page: 1,
};

export const useSpaceMenuItems = () => {
  const { t } = useTranslation();
  const jwt = useToken();
  const navigate = useNavigate();

  const [userLS, setUserLS] = useLocalStorageState('cms.user');
  const [user] = useUser(userLS?.data?.id);
  const { isRoleAdmin, permissions, isAdmin, planLimits } =
    useContext(UserContext);

  const [darkMode] = useDarkMode();
  const { space, buildUrlWithSpace } = useSelectedSpace();

  const { data: apiKeysData } = useApiKeys(APIKEY_PARAMS);
  const {
    data: contentTypes,
    errors: ctdErrors,
    reload: reloadCtd,
  } = useContentTypes(CTD_PARAMS);

  useApiErrorsToast(ctdErrors);

  const isOverridden = useMemo(() => isViewOverridden(userLS), [userLS]);

  const savePreferences = useCallback(async () => {
    if (!userLS?.data) return;

    const newConfig = { ...(userLS.data.config || {}) };

    if (!newConfig.viewPreferences) {
      newConfig.viewPreferences = getUserPreferences(userLS);
    } else {
      newConfig.viewPreferences.isOverridden = !isOverridden;
    }

    putUserConfig(jwt, null, {
      id: userLS.data.id,
      config: newConfig,
    }).catch(() => {
      //do nothing and update config for current session
    });

    const newUser = { ...userLS };
    newUser.data.config = newConfig;

    setUserLS(newUser);
  }, [isOverridden, jwt, setUserLS, userLS]);

  useEffect(() => {
    if (!userLS?.data) return;
    if (userLS.data.workType && !userLS.data.config?.viewPreferences) {
      savePreferences();
    }
  }, [savePreferences, userLS?.data]);

  const hasPermissions = useCallback(
    (pageKey) => {
      switch (pageKey) {
        case 'content':
        case 'favorites':
          return permissions?.cosVisible();
        case 'media':
          return permissions?.canCo('_media');
        case 'tags':
          return permissions?.canCo('_tag');
        case 'definitionBuilder':
          return permissions?.ctdsVisible();
        case 'webhooks':
          return permissions?.canCo('_webhooks');
        case 'plugins':
          return permissions?.canCo('_plugin_settings');
        case 'userRoles':
          return (
            isAdmin &&
            (!!planLimits?.user_roles_enabled ||
              !!planLimits?.predefined_user_roles) &&
            process.env.REACT_APP_ENABLE_USER_ROLES.split(',').join(',') ===
              'true'
          );
        case 'admin': {
          return isRoleAdmin;
        }
        case 'togglePreferences':
          return hasHiddenData(userLS);

        default:
          return true;
      }
    },
    [
      isAdmin,
      isRoleAdmin,
      permissions,
      planLimits?.predefined_user_roles,
      planLimits?.user_roles_enabled,
      userLS,
    ],
  );

  const [contentItems, pinedItems] = useMemo(() => {
    if (!contentTypes) return [[], []];

    const pinedElements = [];

    const dict = contentTypes.reduce((folders, el) => {
      if (!permissions?.canCo(el.name)) return folders;
      const hasFolder = el.label.includes('/');
      const [splitFolderName, ...subfolderNameParts] =
        el.label?.split('/') || [];
      const folderName = hasFolder ? splitFolderName : '';
      const splitLabel = hasFolder ? subfolderNameParts.join('/') : el.label;

      const pinedContent = userLS?.data?.config?.pinedDefinitions;
      const isPinned = pinedContent?.[space]?.includes(el.id);

      const menuElement = {
        key: splitLabel,
        name: el.name,
        id: el.id,
        title: splitLabel || el.label,
        link: buildUrlWithSpace(`content-type-objects/${el.name}`),
        pined: isPinned,
      };

      if (isPinned) {
        pinedElements.push({
          ...menuElement,
          title: el.label,
          link: buildUrlWithSpace(
            `content-type-objects/${el.name}?favorites=1`,
          ),
          id: el.id,
          key: `${el.label}-pinned`,
        });
      }

      if (folders[folderName]) folders[folderName].push(menuElement);
      else folders[folderName] = [menuElement];
      return folders;
    }, {});
    const items = [];

    Object.keys(dict)
      .sort((a, b) => {
        if (!a) return 1;
        if (!b) return -1;

        return a > b ? 1 : -1;
      })
      .forEach((key) => {
        if (key === '') items.push(...dict[key]);
        else {
          items.push({
            key: key,
            name: key,
            title: key,
            children: dict[key],
          });
        }
      });

    return [items || [], pinedElements || []];
  }, [
    contentTypes,
    permissions,
    userLS?.data?.config?.pinedDefinitions,
    space,
    buildUrlWithSpace,
  ]);

  const selectedSpaceHighlight =
    "after:content-['•'] after:text-lg after:text-blue after:text-3xl after:absolute " +
    'after:top-1/2 after:-translate-y-1/2 after:right-[5px] after:leading-none font-medium';

  const menuSpaceContentItems = useMemo(() => {
    if (!user) return null;

    const spacesMenuOptions = [];

    if (user?.spaces?.length) {
      const userSpaces = user?.spaces.map((userSpace) => {
        const isSelected = userSpace.id === space;
        return {
          ...userSpace,
          key: userSpace.id,
          title: userSpace.name,
          additionalClasses: isSelected ? selectedSpaceHighlight : '',
          onClick: () => {
            navigate(`/s/${userSpace.slug}`);
            if (!isSelected) FlotiqPlugins.unregisterAllPlugins();
          },
        };
      });
      spacesMenuOptions.push(...userSpaces);
    }

    if (isAdmin) {
      spacesMenuOptions.push({
        key: 'manage-spaces',
        title: t('Spaces.ManageSpaces'),
        value: 'manage-spaces',
        link: '/organization',
        additionalClasses: 'text-blue-600 underline',
      });
      spacesMenuOptions.push({
        key: 'add-space',
        title: t('Spaces.PlusAddSpace'),
        value: 'manage-spaces',
        link: '/space/add',
        additionalClasses: 'text-blue-600 underline',
      });
    }

    return spacesMenuOptions;
  }, [user, isAdmin, space, selectedSpaceHighlight, navigate, t]);

  const menuItemProps = useMemo(
    () => [
      {
        key: 'spaces',
        icon: darkMode ? (
          <FolderIconWhite className="w-6 min-w-6" />
        ) : (
          <FolderIcon className="w-6 min-w-6" />
        ),
        title:
          menuSpaceContentItems?.filter((el) => el.id === space)?.[0]?.title ||
          '',
        hidePin: true,
        children: menuSpaceContentItems,
        titleClassName: 'font-bold',
        className: `h-10 ${
          !menuSpaceContentItems?.length && 'pointer-events-none'
        }`,
        popover: true,
      },
      {
        key: 'dashboard',
        icon: darkMode ? (
          <FourSquaresColorWhite className="w-6 min-w-6" />
        ) : (
          <FourSquaresColor className="w-6 min-w-6" />
        ),
        title: t('Global.Dashboard'),
        link: buildUrlWithSpace(''),
      },
      {
        key: 'favorites',
        icon: <StarFavIcon className="w-6 min-w-6" />,
        title: t('Global.Favorite'),
        children: pinedItems,
      },
      {
        key: 'media',
        icon: darkMode ? (
          <MediaColorIconWhite className="w-6 min-w-6" />
        ) : (
          <MediaColorIcon className="w-6 min-w-6" />
        ),
        title: t('Global.MediaLibrary'),
        link: buildUrlWithSpace('media'),
      },
      {
        key: 'tags',
        icon: darkMode ? (
          <TagsColorIconWhite className="w-6 min-w-6" />
        ) : (
          <TagsColorIcon className="w-6 min-w-6" />
        ),
        title: t('Global.Tags'),
        link: buildUrlWithSpace('content-type-objects/_tag'),
      },
      {
        key: 'userRoles',
        icon: darkMode ? (
          <UsersKeyIconWhite className="w-6 min-w-6" />
        ) : (
          <UsersKeyIcon className="w-6 min-w-6" />
        ),
        title: t('Global.UserRoles'),
        link: buildUrlWithSpace('user-roles'),
      },
      {
        key: 'content',
        icon: darkMode ? (
          <ClipboardColorIconWhite className="w-6 min-w-6" />
        ) : (
          <ClipboardColorIcon className="w-6 min-w-6" />
        ),
        title: t('Global.Content'),
        ...(contentItems.length
          ? { children: contentItems }
          : { link: buildUrlWithSpace('content') }),
      },
      {
        key: 'definitionBuilder',
        icon: darkMode ? (
          <GearColorIconWhite className="w-6 min-w-6" />
        ) : (
          <GearColorIcon className="w-6 min-w-6" />
        ),
        title: t('Global.DefinitionBuilder'),
        link: buildUrlWithSpace('content-type-definitions'),
      },
      {
        key: 'webhooks',
        icon: darkMode ? (
          <ConnectedDotsColorIconWhite className="w-6 min-w-6" />
        ) : (
          <ConnectedDotsColorIcon className="w-6 min-w-6" />
        ),
        title: t('Global.Webhooks'),
        link: buildUrlWithSpace('webhooks'),
      },
      {
        key: 'plugins',
        icon: darkMode ? (
          <PuzzleColorIconWhite className="w-6 min-w-6" />
        ) : (
          <PuzzleColorIcon className="w-6 min-w-6" />
        ),
        title: t('Global.Plugins'),
        link: buildUrlWithSpace('plugins'),
      },
      {
        key: 'starters',
        icon: darkMode ? (
          <LightningColorIconWhite className="w-6 min-w-6" />
        ) : (
          <LightningColorIcon className="w-6 min-w-6" />
        ),
        title: t('Global.Starters'),
        link: buildUrlWithSpace('starters'),
      },
      {
        key: 'apiKeys',
        icon: darkMode ? (
          <KeyColorIconWhite className="w-6 min-w-6" />
        ) : (
          <KeyColorIcon className="w-6 min-w-6" />
        ),
        title: t('Global.APIKeys'),
        link: buildUrlWithSpace('api-keys'),
      },
      {
        key: 'apiDocs',
        icon: darkMode ? (
          <CopyColorIconWhite className="w-6 min-w-6" />
        ) : (
          <CopyColorIcon className="w-6 min-w-6" />
        ),
        title: t('Global.APIDocs'),
        link: generateApiDocs(userLS?.data, apiKeysData?.[0]),
        target: '_blank',
        rel: 'noreferrer',
        onClick: () => {
          markTaskAsDone('publicDoc', user, jwt, setUserLS);
          TagManager.dataLayer({
            dataLayer: {
              event: 'apidocs_visit',
            },
          });
        },
      },
      {
        key: 'admin',
        className: 'border-t border-gray dark:border-slate-800 mt-1.5',
        icon: <StarFavIcon className="w-6 min-w-6" />,
        title: 'Admin',
        hidePin: true,
        children: [
          {
            key: 'all-users',
            icon: <UsersIcon className="w-6 min-w-6" />,
            title: t('Global.AllUsers'),
            link: '/users-data-preview',
          },
          {
            key: 'all-spaces',
            icon: <UsersIcon className="w-6 min-w-6" />,
            title: t('Global.AllSpaces'),
            link: '/spaces-data-preview',
          },
          {
            key: 'plans',
            icon: <UsersIcon className="w-6 min-w-6" />,
            title: t('Global.Plans'),
            link: '/plans',
          },
        ],
      },
      {
        key: 'togglePreferences',
        className:
          'border-t border-gray dark:border-slate-800 text-[15px] min-h-[50px]',
        titleClassName: 'leading-4 line-clamp-2',
        icon: darkMode ? (
          <MenuIconWhite className="w-6 min-w-6" />
        ) : (
          <MenuIcon className="w-6 min-w-6" />
        ),
        title: isOverridden
          ? t('Global.HideAdvancedOptions')
          : t('Global.ShowAdvancedOptions'),
        onClick: savePreferences,
      },
    ],
    [
      darkMode,
      menuSpaceContentItems,
      t,
      buildUrlWithSpace,
      pinedItems,
      contentItems,
      userLS?.data,
      apiKeysData,
      isOverridden,
      savePreferences,
      space,
      user,
      jwt,
      setUserLS,
    ],
  );

  const menuItems = useMemo(() => {
    if (!user) return [];
    if (!space)
      return [
        {
          key: 'dashboard',
          icon: darkMode ? (
            <FourSquaresColorWhite className="w-6 min-w-6" />
          ) : (
            <FourSquaresColor className="w-6 min-w-6" />
          ),
          title: t('Global.Dashboard'),
          link: `/`,
        },
      ];

    const viewPreferences = getUserPreferences(userLS);
    return menuItemProps.filter(
      ({ key }) =>
        hasPermissions(key) && viewPreferences.navigation?.[key] !== false,
    );
  }, [darkMode, hasPermissions, menuItemProps, space, t, user, userLS]);

  return { menuItems, reloadCtd };
};
