import { useQuery } from '@tanstack/react-query';
import useToken from '../../useToken';
import { useMemo } from 'react';
import { checkResponseStatus } from '../../../lib/flotiq-client/response-errors';
import { getQueryCacheKey } from '../../../lib/helpers';
import useSelectedSpace from '../../useSelectedSpace';

/**
 * @param { function } getEntities
 * @param { string|Array } cacheKeyPrefix Cache key for request group
 * @param { object } defaultParams
 * @param { object } defaultOptions
 * @returns { function } hook callback
 */
export const createListingHook = (
  getEntities,
  cacheKeyPrefix,
  defaultParams = {},
  defaultOptions = {},
) => {
  /**
   * @param { object } params
   * @param { { checkStatus: boolean, cancelUnfinishedRequests: boolean  } } hookOptions
   * @param { string|Array } cacheKey Unique key for request in given group
   * @returns {{
   *    data: array,
   *    pagination: { count: number, current_page: number, total_count: number, total_pages: number },
   *    isLoading: boolean,
   *    status: number,
   *    errors: object | null,
   *    reload: function,
   *    isFetching: boolean
   *  }|[
   *   data: array,
   *   pagination: { count: number, current_page: number, total_count: number, total_pages: number },
   *   isLoading: boolean,
   *   status: number,
   *   errors: object | null,
   *   reload: function,
   *   isFetching: boolean
   * ]}
   */
  return (
    params = defaultParams,
    hookOptions = defaultOptions,
    cacheKey = '',
  ) => {
    const jwt = useToken();
    const { space } = useSelectedSpace();
    const {
      checkStatus = true,
      cancelUnfinishedRequests = true,
      pause = false,
      staleTime = 0,
      gcTime = 5 * 60 * 1000, //5 minutes
    } = hookOptions || {};

    const queryKey = useMemo(
      () => getQueryCacheKey(cacheKeyPrefix, cacheKey, params, 'list'),
      [cacheKey, params],
    );

    const {
      isLoading: requestLoading,
      data: response,
      error: errors,
      refetch: reload,
      isPlaceholderData,
      isFetching,
    } = useQuery({
      queryKey,
      queryFn: async ({ signal }) => {
        const result = await getEntities(
          jwt,
          space,
          params,
          cancelUnfinishedRequests ? { signal } : {},
        );
        if (checkStatus) checkResponseStatus(result.body, result.status);
        return Promise.resolve(result);
      },
      enabled: !!jwt && !pause,
      staleTime,
      gcTime,
      retry: 0,
    });

    const isLoading = requestLoading || isPlaceholderData;

    const { data, status, pagination } = useMemo(
      () =>
        response?.body
          ? {
              data: Array.isArray(response.body)
                ? response.body
                : response.body.data,
              status: response?.status,
              pagination: {
                total_pages: response.body.total_pages,
                total_count: response.body.total_count,
                count: response.body.count,
                current_page: response.body.current_page,
              },
            }
          : {
              data: [],
              pagination: {},
              status: response?.status || errors?.status || 0,
            },
      [errors?.status, response?.body, response?.status],
    );

    const result = [
      data,
      pagination,
      isLoading,
      status,
      errors,
      reload,
      isFetching,
    ];
    Object.assign(result, {
      data,
      pagination,
      isLoading,
      status,
      errors,
      reload,
      isFetching,
    });
    return result;
  };
};
