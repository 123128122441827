import { useFormikContext } from 'formik';
import ContentObjectFormContext from '../../../contexts/ContentObjectFormContext';
import { useContext } from 'react';
import NewMediaContext from '../../../contexts/NewMediaContext';
import ElementFromPlugin from '../../../components/ElementFromPlugin/ElementFromPlugin';
import { FormAddElementEvent } from '../../../lib/flotiq-plugins/plugin-events/FormAddElementEvent';
import usePluginResults from '../../../hooks/usePluginResults';

const CustomFormElement = () => {
  const { contentType, initialData, userPlugins, loadedVersion } = useContext(
    ContentObjectFormContext,
  );
  const { onUpload: onMediaUpload } = useContext(NewMediaContext);
  const formik = useFormikContext();

  /**
   * @emits FlotiqPlugins."flotiq.form::add"
   */
  const pluginRenders = usePluginResults(
    'flotiq.form::add',
    FormAddElementEvent,
    {
      contentType,
      formik,
      onMediaUpload,
      initialData,
      userPlugins,
      loadedVersion,
    },
  );

  return pluginRenders?.length ? (
    <ElementFromPlugin results={pluginRenders} />
  ) : null;
};

export default CustomFormElement;
