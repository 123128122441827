import { useMemo } from 'react';
import useSessionStorageState from 'use-session-storage-state';
import {
  getConstraints,
  deleteContentObject,
  deleteContentType,
  getContentObject,
  getContentObjects,
  getContentType,
  listContentTypes,
  putContentObject,
  listUnsplashMedia,
  putContentType,
  getSearchObject,
  getUser,
  putUser,
  deleteUser,
  getUsers,
  getContentObjectVersions,
  getApiKey,
  listWebhooks,
  getWorkflow,
  getWorkflowDefinition,
  getPlugins,
  getAllUsers,
  getPluginsLibrary,
  getUserRoles,
  getUserRole,
  putUserRole,
  deleteUserRole,
  getPlans,
  getPublicPlans,
  getPlan,
  putPlan,
  deletePlan,
  getAllSpaces,
  getSpace,
  putSpace,
  deleteSpace,
  getSpacesList,
  getSpacePlanHistory,
  getAllUserRoles,
  getAllRolesAssigned,
} from '../../lib/flotiq-client';
import {
  getMarketplacePlugins,
  getStarters,
  getTutorial,
} from '../../lib/flotiq-client/external-services-requests';
import { createListingHook } from './hook-builders/listing-hook-builder';
import { createEntityHook } from './hook-builders/entity-hook-builder';

export const useContentTypes = createListingHook(
  listContentTypes,
  'content-types',
);

export const useContentType = createEntityHook(
  getContentType,
  putContentType,
  deleteContentType,
  'content-types',
  'contentTypeName',
);

const baseCTOHook = createListingHook(getContentObjects, 'content-objects');

export const useContentObjects = (
  contentTypeName,
  optionalParams = null,
  options = null,
) => {
  const completeParams = useMemo(
    () => ({ contentTypeName, ...optionalParams }),
    [contentTypeName, optionalParams],
  );
  return baseCTOHook(completeParams, options);
};

const baseObjectHook = createEntityHook(
  getContentObject,
  putContentObject,
  deleteContentObject,
  'content-objects',
);
export const useContentObject = (contentTypeName, id, options = null) => {
  const params = useMemo(() => ({ contentTypeName }), [contentTypeName]);
  return baseObjectHook(id, params, options);
};

const baseObjectVersionsHook = createListingHook(
  getContentObjectVersions,
  'content-objects',
);
export const useContentObjectVersions = (
  contentTypeName,
  id,
  hookParams = null,
  hookOptions = null,
) => {
  const params = useMemo(
    () => ({ contentTypeName, id, ...hookParams }),
    [contentTypeName, hookParams, id],
  );
  return baseObjectVersionsHook(params, hookOptions, 'versions');
};

export const useUnsplashMedia = createListingHook(
  listUnsplashMedia,
  'unsplash',
);

export const useMediaTags = (params) => {
  return useContentObjects('_tag', params);
};

export const useConstraints = createEntityHook(
  getConstraints,
  null,
  null,
  'constraints',
  'name',
);

export const useDefinitionsCount = (hookParams, hookOptions) => {
  return useConstraints('cto-count', hookParams, hookOptions);
};

export const useWorkflowDefinition = createEntityHook(
  getWorkflowDefinition,
  null,
  null,
  'workflow',
  'name',
);
export const baseWorkflowHook = createEntityHook(
  getWorkflow,
  null,
  null,
  'content-objects',
  'objectId',
);

export const useWorkflow = (objectType, id, revision) => {
  const params = useMemo(
    () => ({ objectType, revision }),
    [objectType, revision],
  );
  return baseWorkflowHook(id, params, null, 'workflow');
};

export const useUsers = createListingHook(getUsers, 'users');

export const baseUserHook = createEntityHook(
  getUser,
  putUser,
  deleteUser,
  'users',
);
export const useUser = (id, params, options) => {
  const [impersonate] = useSessionStorageState('cms.impersonate');
  const hookOptions = useMemo(
    () => ({
      staleTime: Infinity,
      ...options,
    }),
    [options],
  );
  return baseUserHook(impersonate?.id || id, params, hookOptions);
};

export const useAllUsers = createListingHook(getAllUsers, ['users', 'admin']);

export const useAllSpaces = createListingHook(getAllSpaces, [
  'spaces',
  'admin',
]);

export const useSearch = createListingHook(getSearchObject, 'search');

export const baseApiKeysHook = createListingHook(getApiKey, 'api-keys');
export const useApiKeys = (params, options) => {
  const hookOptions = useMemo(
    () => ({
      staleTime: Infinity,
      ...options,
    }),
    [options],
  );
  return baseApiKeysHook(params, hookOptions);
};

export const useWebhook = createListingHook(listWebhooks, 'content-objects');

export const usePlugins = createListingHook(getPlugins, 'legacy-plugins');

export const usePluginsLibrary = createListingHook(
  getPluginsLibrary,
  'legacy-plugins-library',
);

export const useUserRoles = createListingHook(getUserRoles, ['users', 'roles']);

export const useUserRole = createEntityHook(
  getUserRole,
  putUserRole,
  deleteUserRole,
  ['users', 'roles'],
);

export const useAllPlans = createListingHook(getPlans, ['plans', 'admin']);
export const useAllPublicPlans = createListingHook(getPublicPlans, 'plans');

export const usePlan = createEntityHook(getPlan, putPlan, deletePlan, 'plans');

export const usePluginsSettings = (params, options = null) => {
  const hookOptions = useMemo(
    () => ({
      staleTime: Infinity,
      ...options,
    }),
    [options],
  );
  return useContentObjects('_plugin_settings', params, hookOptions);
};

export const useSpace = createEntityHook(
  getSpace,
  putSpace,
  deleteSpace,
  'spaces',
);

export const useSpacesList = createListingHook(getSpacesList, 'spaces');

const baseSpacePlanHistory = createListingHook(getSpacePlanHistory, [
  'spaces',
  'history',
]);
export const useSpacePlanHistory = (
  spaceId,
  optionalParams = null,
  options = null,
) => {
  const completeParams = useMemo(
    () => ({ id: spaceId, ...optionalParams }),
    [spaceId, optionalParams],
  );
  return baseSpacePlanHistory(completeParams, options);
};

export const useStarters = createListingHook(getStarters, 'starters');

export const useMarketplacePlugins = createListingHook(
  getMarketplacePlugins,
  'marketplace-plugins',
);

export const useRawTutorial = createListingHook(getTutorial, 'tutorial');

export const useAllUserRoles = createListingHook(getAllUserRoles, [
  'user',
  'roles',
  'all',
]);

const baseAllRolesAssignedHook = createListingHook(getAllRolesAssigned, [
  'users',
  'roles',
  'assigned',
]);
export const useAllRolesAssigned = (userId, params = null, options = null) => {
  const completeParams = useMemo(
    () => ({ id: userId, ...params }),
    [params, userId],
  );
  return baseAllRolesAssignedHook(completeParams, options);
};
