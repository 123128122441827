export const getDetailsMap = (t) => [
  {
    planCopyProperty: 'name',
    planProperty: 'name',
    label: t('Global.Name'),
  },
  {
    planCopyProperty: 'defaultPlan',
    planProperty: 'defaultPlan',
    label: t('Plans.DefaultPlan'),
    isBoolean: true,
  },
  {
    planCopyProperty: 'cto_limit',
    planProperty: 'ctoLimit',
    label: t('Global.ContentTypeObjects'),
  },
  {
    planCopyProperty: 'ctd_limit',
    planProperty: 'ctdLimit',
    label: t('Global.ContentTypeDefinitions'),
  },
  {
    planCopyProperty: 'file_quota',
    planProperty: 'fileQuota',
    label: t('Global.FileQuota'),
    mode: 'MB',
  },
  {
    planCopyProperty: 'max_asset_size',
    planProperty: 'maxAssetSize',
    label: t('Global.MaxAssetSize'),
    mode: 'MB',
  },
  {
    planCopyProperty: 'scoped_keys_limit',
    planProperty: 'scopedKeysLimit',
    label: t('Global.ScopedApiKeysCount'),
  },
  {
    planCopyProperty: 'scoped_keys_docs',
    planProperty: 'scopedKeysDocs',
    label: t('Global.ScopedApiKeysDocs'),
    isBoolean: true,
  },
  {
    planCopyProperty: 'team_members_limit',
    planProperty: 'teamMembersLimit',
    label: t('Global.TeamMembers'),
  },
  {
    planCopyProperty: 'webhooks_limit',
    planProperty: 'webhooksLimit',
    label: t('Global.Webhooks'),
  },
  {
    planCopyProperty: 'hosted_webhooks_limit',
    planProperty: 'hostedWebhooksLimit',
    label: t('Global.HostedWebhook'),
    mode: 'compact-number',
  },
  {
    planCopyProperty: 'api_calls_limit',
    planProperty: 'apiCallsLimit',
    label: t('Global.APICalls'),
  },
  {
    planCopyProperty: 'official_plugins_limit',
    planProperty: 'officialPluginsLimit',
    label: t('Global.OfficialPlugins'),
  },
  {
    planCopyProperty: 'custom_plugins_limit',
    planProperty: 'customPluginsLimit',
    label: t('Global.CustomPlugins'),
  },
  {
    planCopyProperty: 'allowed_hosts_for_custom_plugins',
    planProperty: 'allowedHostsForCustomPlugins',
    label: t('Global.AllowedHosts'),
  },
  {
    planCopyProperty: 'user_roles_enabled',
    planProperty: 'userRolesEnabled',
    label: t('Global.UserRoles'),
    isBoolean: true,
  },
  {
    planCopyProperty: 'createdAt',
    planProperty: 'createdAt',
    label: t('Global.CreatedAt'),
    isDate: true,
  },
];
