import { useCallback, useMemo } from 'react';
import useToken from '../../useToken';
import useSelectedSpace from '../../useSelectedSpace';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { checkResponseStatus } from '../../../lib/flotiq-client/response-errors';
import { getQueryCacheKey } from '../../../lib/helpers';

/**
 * @param { function } getEntity
 * @param { function } putEntity
 * @param { function } removeEntity
 * @param { string|Array } cacheKeyPrefix - cache key for request group
 * @param { string } idFieldName
 * @param { object } defaultParams
 * @param { object } defaultOptions
 * @returns { function } hook callback
 */
export const createEntityHook = (
  getEntity,
  putEntity,
  removeEntity,
  cacheKeyPrefix,
  idFieldName = 'id',
  defaultParams = {},
  defaultOptions = {},
) => {
  /**
   * @param { string } id
   * @param { object } hookParams
   * @param { { checkStatus: boolean, cancelUnfinishedRequests: boolean } } hookOptions
   * @param { string|Array } cacheKey unique key for request in given group
   * @returns {{
   *  entity: object|null,
   *  isLoading: bool,
   *  status: number,
   *  errors: object|null ,
   *  reload: function,
   *  updateEntity: function,
   *  deleteEntity: function,
   *  isFetching: boolean
   * }|[
   *  entity: object|null,
   *  isLoading: bool,
   *  status: number,
   *  errors: object|null ,
   *  reload: function,
   *  updateEntity: function,
   *  deleteEntity: function,
   *  isFetching: boolean
   * ]}
   */
  return (
    id,
    hookParams = defaultParams,
    hookOptions = defaultOptions,
    cacheKey = '',
  ) => {
    const queryClient = useQueryClient();
    const jwt = useToken();
    const { space } = useSelectedSpace();
    const {
      checkStatus = true,
      cancelUnfinishedRequests = true,
      pause = false,
      staleTime = 0,
      gcTime = 5 * 60 * 1000, //5 minutes
    } = hookOptions || {};

    const params = useMemo(
      () => ({ [idFieldName]: id, ...hookParams }),
      [id, hookParams],
    );

    const queryKey = useMemo(
      () => getQueryCacheKey(cacheKeyPrefix, cacheKey, params, 'entity'),
      [cacheKey, params],
    );

    const {
      isLoading: requestLoading,
      data: response,
      error: errors,
      refetch: reload,
      isPlaceholderData,
      isFetching,
    } = useQuery({
      queryKey,
      queryFn: async ({ signal }) => {
        const result = await getEntity(
          jwt,
          space,
          params,
          cancelUnfinishedRequests ? { signal } : {},
        );
        if (checkStatus) checkResponseStatus(result.body, result.status);
        return Promise.resolve(result);
      },
      enabled: !!id && !!jwt && !pause,
      staleTime,
      gcTime,
      retry: 0,
    });

    const isLoading = requestLoading || isPlaceholderData;

    const { entity, status } = useMemo(
      () => ({
        entity: response?.body || null,
        status: response?.status || errors?.status || 0,
      }),
      [errors?.status, response?.body, response?.status],
    );

    const onMutateSuccess = useCallback(
      async (response, isDelete) => {
        if (response.ok) {
          queryClient.invalidateQueries({
            queryKey: [cacheKeyPrefix],
            refetchType: 'none',
          });
          queryClient.setQueryData(queryKey, ({ body }) => ({
            status: response.status,
            body: isDelete ? null : { ...body, ...response.body },
          }));
        }
        return response;
      },
      [queryClient, queryKey],
    );

    const onUpdateMutateSuccess = useCallback(
      async (response) => onMutateSuccess(response, false),
      [onMutateSuccess],
    );

    const onDeleteMutateSuccess = useCallback(
      async (response) => onMutateSuccess(response, true),
      [onMutateSuccess],
    );

    const { mutateAsync: updateEntity } = useMutation({
      mutationFn: async (values = {}) =>
        putEntity(jwt, space, {
          [idFieldName]: entity?.[idFieldName],
          ...values,
        }),
      onSuccess: onUpdateMutateSuccess,
    });

    const { mutateAsync: deleteEntity } = useMutation({
      mutationFn: async (params = {}) =>
        removeEntity(jwt, space, {
          [idFieldName]: entity[idFieldName],
          ...params,
        }),
      onSuccess: onDeleteMutateSuccess,
    });

    const result = [
      entity,
      isLoading,
      status,
      errors,
      reload,
      updateEntity,
      deleteEntity,
      isFetching,
    ];
    Object.assign(result, {
      entity,
      isLoading,
      status,
      errors,
      reload,
      updateEntity,
      deleteEntity,
      isFetching,
    });
    return result;
  };
};
