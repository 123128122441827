import { IFlotiqPluginEvent } from './IFlotiqPluginEvent';

/**
 * An event that is fired when a grid is rendered.
 * Returning anything will embed that result above the grid.
 *
 * @memberof FlotiqPlugins.Events
 * @event "flotiq.grid::add"
 * @type {GridAddElementEvent}
 * @returns {null|string|array|number|boolean|HTMLElement|ReactElement}
 *          Null if rendering should be passed to either Flotiq or other plugins.
 *          Renderable result if plugin wants to place the item above the grid.
 */

/**
 * @class
 * @memberof FlotiqPlugins.Events
 * @name GridAddElementEvent
 *
 * @property {string} contentTypeName Content type api name
 * @property {object} contentType Content type that includes the field
 * @property {array} contentObjects Content Type Objects for current page
 * @property {object} pagination Pagination returned from API
 * @property {func} handlePageChange Function to change page
 * @property {func} reload Function to update grid data
 * @property {boolean} isFetching indicates whether the query is currently being fetched (including background-fetching)
 * @property {boolean} isLoading  indicates whether the query is in the process of fetching data for the first time
 */
export class GridAddElementEvent extends IFlotiqPluginEvent {
  contentTypeName;
  contentType;
  contentObjects;
  pagination;
  handlePageChange;
  reload;

  isFetching;
  isLoading;
}
