import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { twMerge } from 'tailwind-merge';
import Input from '../../../../components/Input/Input';
import Button from '../../../../components/Button/Button';
import {
  ArrowUpIcon,
  DeleteIcon,
  PlusCircleOutlineIcon,
} from '../../../../images/shapes';
import HelpErrorTextsTemplate from '../../../../components/HelpErrorTextsTemplate/HelpErrorTextsTemplate';
import { getTestProps } from '../../../../lib/helpers';
import RequiredTemplate from '../../../../components/RequiredTemplate/RequiredTemplate';

const OptionRender = ({
  option,
  idx,
  useOptionsWithLabels,
  currentValue,
  focusIdx,
  onBlur,
  onChange,
  onKeyDown,
  onUp,
  onDown,
  onDelete,
  error,
  disabled,
  testId,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row gap-2">
      {useOptionsWithLabels ? (
        <>
          <Input
            name={`config.options[${idx}].value`}
            value={option.value}
            onBlur={onBlur}
            onChange={(e) =>
              onChange({ value: e.target.value, label: option.label }, idx)
            }
            error={error}
            onKeyDown={(e) => onKeyDown(e, option, currentValue, idx)}
            placeholder={t('Global.Value')}
            disabled={disabled}
            testId={testId}
            {...(focusIdx === idx && { autoFocus: true })}
          />
          <Input
            name={`config.options[${idx}].label`}
            value={option.label}
            onBlur={onBlur}
            onChange={(e) =>
              onChange({ value: option.value, label: e.target.value }, idx)
            }
            error={error}
            onKeyDown={(e) => onKeyDown(e, option, currentValue, idx)}
            placeholder={t('Global.Label')}
            disabled={disabled}
            testId={testId}
          />
        </>
      ) : (
        <Input
          name={`config.options[${idx}]`}
          value={option}
          onBlur={onBlur}
          onChange={(e) => onChange(e.target.value, idx)}
          error={error}
          onKeyDown={(e) => onKeyDown(e, option, currentValue, idx)}
          disabled={disabled}
          testId={testId}
          {...(focusIdx === idx && { autoFocus: true })}
        />
      )}
      <div className="flex flex-row gap-1 items-center">
        {currentValue.length > 1 && (
          <>
            <Button
              onClick={() => onUp(idx)}
              iconImage={
                <ArrowUpIcon
                  className={twMerge(
                    'h-3 text-slate-400',
                    idx !== 0 && 'hover:text-gray-700',
                  )}
                />
              }
              buttonColor="borderless"
              additionalClasses="w-fit"
              disabled={idx === 0 || disabled}
              tabIndex={-1}
              noPaddings
              {...getTestProps(testId, 'up', 'testId')}
            />
            <Button
              onClick={() => onDown(idx)}
              iconImage={
                <ArrowUpIcon
                  className={twMerge(
                    'h-3 rotate-180 text-slate-400',
                    idx !== currentValue.length - 1 && 'hover:text-gray-700',
                  )}
                />
              }
              buttonColor="borderless"
              additionalClasses="w-fit"
              disabled={idx === currentValue.length - 1 || disabled}
              tabIndex={-1}
              noPaddings
              {...getTestProps(testId, 'down', 'testId')}
            />
          </>
        )}
        <Button
          onClick={() => onDelete(idx)}
          iconImage={
            <DeleteIcon
              className={twMerge('h-3 text-slate-400 hover:text-gray-700')}
            />
          }
          buttonColor="borderless"
          additionalClasses="w-fit"
          tabIndex={-1}
          disabled={disabled}
          noPaddings
          {...getTestProps(testId, 'delete', 'testId')}
        />
      </div>
    </div>
  );
};

const OptionsRender = ({
  arrayHelpers,
  label,
  error,
  useOptionsWithLabels,
  testId,
  additionalClasses,
  helpText,
  required,
  disabled,
}) => {
  const [focusIdx, setFocusIdx] = useState(null);

  const value = useMemo(() => {
    return getIn(arrayHelpers?.form.values, arrayHelpers?.name) || [];
  }, [arrayHelpers?.name, arrayHelpers?.form]);

  const onBlur = useCallback(
    () =>
      arrayHelpers?.form.handleBlur({
        target: { name: arrayHelpers.name },
      }),
    [arrayHelpers?.form, arrayHelpers?.name],
  );

  const handleOptionChange = useCallback(
    (value, idx) => {
      arrayHelpers.replace(idx, value);
    },
    [arrayHelpers],
  );

  const handleAddOption = useCallback(() => {
    arrayHelpers.push(
      useOptionsWithLabels
        ? {
            value: '',
            label: '',
          }
        : '',
    );
    onBlur();
  }, [arrayHelpers, onBlur, useOptionsWithLabels]);

  const handleOrderUp = useCallback(
    (idx) => {
      arrayHelpers.swap(idx - 1, idx);
    },
    [arrayHelpers],
  );

  const handleOrderDown = useCallback(
    (idx) => {
      arrayHelpers.swap(idx + 1, idx);
    },
    [arrayHelpers],
  );

  const handleDeleteOption = useCallback(
    async (idx) => {
      arrayHelpers.remove(idx);
    },
    [arrayHelpers],
  );

  const handleKeyDownAction = useCallback(
    (e, option, currentValue, idx) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleAddOption();
        setFocusIdx(idx + 1);
      } else if (e.key === 'Backspace' && option === '') {
        e.preventDefault();
        handleDeleteOption(idx);
        if (currentValue.length > 1) setFocusIdx(idx === 0 ? idx + 1 : idx - 1);
      } else if (e.key === 'ArrowUp' && idx > 0) {
        e.preventDefault();
        handleOrderUp(idx);
      } else if (e.key === 'ArrowDown' && idx < currentValue.length - 1) {
        e.preventDefault();
        handleOrderDown(idx);
      }
    },
    [handleAddOption, handleDeleteOption, handleOrderDown, handleOrderUp],
  );

  return (
    <div
      className={twMerge(
        'w-full',
        'flex',
        'flex-col',
        'relative',
        additionalClasses,
      )}
    >
      {label && (
        <label className="text-sm text-slate-400 dark:text-gray-200 mb-1">
          {label}
          {required && <RequiredTemplate />}
        </label>
      )}
      {value && value.length > 0 && (
        <div className="mb-2 space-y-2">
          {value.map((option, idx, currentValue) => {
            const keyValue = idx;
            const optionError = typeof error !== 'string' ? error?.[idx] : null;
            return (
              <OptionRender
                key={keyValue}
                option={option}
                useOptionsWithLabels={useOptionsWithLabels}
                idx={idx}
                currentValue={currentValue}
                focusIdx={focusIdx}
                onBlur={onBlur}
                onChange={handleOptionChange}
                onKeyDown={handleKeyDownAction}
                onUp={handleOrderUp}
                onDown={handleOrderDown}
                onDelete={handleDeleteOption}
                error={optionError}
                disabled={disabled}
                {...getTestProps(testId, `option-${idx}`, 'testId')}
              />
            );
          })}
        </div>
      )}
      <Button
        onClick={handleAddOption}
        buttonColor="blueBordered"
        buttonSize="sm"
        iconImage={<PlusCircleOutlineIcon className="w-4" />}
        additionalClasses="w-fit"
        disabled={disabled}
        {...getTestProps(testId, 'add-option', 'testId')}
      />
      <HelpErrorTextsTemplate
        helpText={helpText}
        error={typeof error === 'string' ? error : null}
        testId={testId}
      />
    </div>
  );
};

export default OptionsRender;

OptionsRender.propTypes = {
  /**
   * Array helpers from formik array field
   */
  arrayHelpers: PropTypes.object.isRequired,
  /**
   * Options field label
   */
  label: PropTypes.string,
  /**
   * Errors for options
   */
  error: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  /**
   * If options should be rendered as key-value pair (default it is an array of string)
   */
  useOptionsWithLabels: PropTypes.bool,
  /**
   * Component test id
   */
  testId: PropTypes.string,
  /**
   * Additional container classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Help text under block input
   */
  helpText: PropTypes.string,
  /**
   * If field is required
   */
  required: PropTypes.bool,
  /**
   * If field is disabled
   */
  disabled: PropTypes.bool,
};

OptionsRender.defaultProps = {
  label: '',
  error: null,
  useOptionsWithLabels: false,
  testId: '',
  additionalClasses: '',
  helpText: '',
  required: false,
  disabled: false,
};
